import React from 'react';

function About(props) {
    return (
        <div>
            <h6>
                <p> {`Made by Stef :)`} </p>
            </h6>
        </div>
    );
}

export default About;