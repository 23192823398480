import React, { useState } from 'react';

function Search(props) {
    const [keyword, setKeyword] = useState("")
    const handleOnChange = (e) => {
        e.preventDefault()
        setKeyword(e.target.value)
    }
    const handleOnClick = (e) => {
        e.preventDefault()
        props.handleSearchKeyword(keyword)
    }

    return (
        <div className="search">
            <input 
            className="search-input"
            type="text" 
            value={keyword} 
            onChange={handleOnChange}>
            </input>
            <button className="search-button" onClick={handleOnClick}>
            ✨Get Gems
            </button>
        </div>
    );
}

export default Search;